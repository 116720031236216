<template>
  <v-app>
    <v-container fill-height fluid>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12" lg="8" class="d-none d-lg-block">
          <div class="d-flex justify-space-around align-center">
            <v-img lazy-src="/img/login/login-manCircle01.png" src="/img/login/login-manCircle01.png"
              style="position:absolute;" max-width="600" min-width="200" class="mx-auto anim-scale" aspect-ratio="1"></v-img>
            <v-img lazy-src="/img/login/dummy-inversion.png" contain src="/img/login/dummy-inversion.png"
              max-width="700" class="floating"></v-img>
          </div>

        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-container class="text-center px-5">
            <div class="d-flex flex-column justify-center">
              <div class="logo mx-auto mb-10">
                <v-img src="/logo2.jpg" max-width="300"></v-img>
              </div>

              <div class="text-center">
                <h3 class="text-h3 font-weight-bold">Bienvenido</h3>
                <p class="grey--text">Inicie sesión en su cuenta</p>
              </div>
            </div>

            <ng-form :form="form">
              <v-row no-gutters>
                <ng-text :form="form" :options="form.getCtrolConfig('user')" />
                <ng-passwd class="mt-2" :form="form" :options="form.getCtrolConfig('passwd')"
                  v-on:keyup.enter="loginWithPasswd" />
              </v-row>
              <v-row class="text-center">
                <v-col cols="12"> <router-link to="/auth/recover" class="text-h5 mx-auto router-link">¿Olvidaste tu
                    contraseña?</router-link></v-col>
                <v-col cols="12">
                  <v-btn block color="primary" @click="loginWithPasswd()" class="mr-1">
                    Ingresar
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn to="/payments" color="primary" outlined class="text-h5">
                    <v-icon class="mr-2">mdi-cash-multiple</v-icon>
                    Pagos en línea y PSE</v-btn>
                </v-col>
              </v-row>
            </ng-form>
            <v-row>
              <v-col>
                <v-btn @click="modal = true" text class="text-h5">Términos y
                  condiciones</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="modal" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ titleTerms }}
        </v-card-title>

        <v-card-text v-html="terms">

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="modal = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<style>
.floating {
  animation: float 6s ease-in-out infinite;
}
.anim-scale {
  animation: scale 6s ease-in-out infinite;
}
@keyframes scale {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
</style>

<script>
import { NgForm, handler } from '@/components/gui/NgForm';
import * as firebase from 'firebase';
import {
  mapState,
} from 'vuex'

export default {
  name: 'Login',
  data: () => ({
    form: null,
    modal: false,
    amount: '',
    titleTerms: '',
    terms: '',
    social: [
      /*  {
          color: '#55ACEE',
          icon: 'mdi-twitter',
          type: 'twitter',
          text: 'Ingresar con Twitter',
        },*/
      {
        color: '#3B5998',
        icon: 'mdi-facebook',
        type: 'facebook',
        text: 'Ingresar con Facebook',
      },
      {
        color: '#DD4b39',
        icon: 'mdi-google',
        type: 'google',
        text: 'Ingresar con Google',
      }
    ],
  }),
  created() {
    const configFormMpn = {
      "user": {
        "name": "user",
        "label": "Usuario",

        "validate": ["required"],
        "prepend-inner-icon": "mdi-card-account-details",
        "required": true,
        dense: false,
        outlined: true
      },
      "passwd": {
        "name": "passwd",
        "label": "Contraseña",
        "type": "password",
        "validate": ["required"],
        "prepend-inner-icon": "mdi-lock-question",
        "required": true,
        dense: false,
        outlined: true
      },
    };
    const form = new NgForm(configFormMpn, this)
    this.form = new Proxy(form, handler)

    this.form.init();

  },
  computed: {
    error() {
      return this.$store.getters.error
    },
  },
  mounted() {
    this.$store.commit('setTransaction', null);
    this.$http.get('api/terms').then(response => {
      console.log(response)
      this.titleTerms = response.data.item.name;
      this.terms = response.data.item.body_template;
    })
  },
  methods: {
    onDismissed() {
      this.$store.dispatch('clearError')
    },
    loginWithPasswd() {
      this.$store.commit('setLoading', true);
      this.$auth.authenticated(this.form.value).then((res) => {
        this.$store.commit('setLoading', false);
        ///console.log(res)
        if (res.success) {
          localStorage.setItem('ip', res.ip);
          localStorage.setItem('lastLogin', res.last);
          this.$store.commit('setAuth', res.payload);
          this.$router.push('/admin/home')
        } else {
          //if(res.msg){
          this.$store.commit('setError', 'El usuario y/o contraseña son incorrectos.');
          // }
        }

      }).catch((e) => {
        this.$store.commit('setLoading', false);
        this.$store.commit('setError', e);
      })
    },
    /* login (type) {
       console.log(type)
       switch (type) {
         case 'google':
           this.signUserInGoogleAuth()
           break
         case 'facebook':
           this.signUserInFacebookAuth();
           break
         case 'twitter':
           this.signUserInTwitterAuth();
           break
       }
     },*/
    /*loginServer (user) {
      if (user) {
        this.$store.commit('setLoading', true);
        this.$auth.authenticatedUserFirebase(user).then( (res) => {
          
          this.$store.commit('setLoading', false);
          if (res.success) {
            this.$store.commit('setAuth', res.data);
            this.$router.push('/admin')
          } else {
            this.$store.commit('setError', res.msg);
          }
        });
      }
    },*/
    /*signUserInGoogleAuth () {
      firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(
          response => {
            const newUser = {
              uid: response.user.uid,
              name: response.user.displayName,
              email: response.user.email,
              type: 'google',
              photoUrl: response.user.photoURL
            }
            this.loginServer (newUser);
          }
        )
        .catch( (error) => {
            console.log(error)
          }
        )
    },
  signUserInFacebookAuth () {
    firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then(
        response => {
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            type: 'facebook',
            photoUrl: response.user.photoURL
          }
          this.loginServer (newUser);
        }
      )
      .catch(
        error => {
          console.log(error)
        }
      )
  },
  signUserInGithubAuth () {
    firebase.auth().signInWithPopup(new firebase.auth.GithubAuthProvider())
      .then(
        response => {
          //commit('setLoading', false)
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            type: 'github',
            photoUrl: response.user.photoURL
          }
          this.loginServer (newUser);
        }
      )
      .catch(
        error => {
          console.log(error)
        }
      )
  },
  signUserInTwitterAuth () {
    firebase.auth().signInWithPopup(new firebase.auth.TwitterAuthProvider())
      .then(
        response => {
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            type: 'twitter',
            photoUrl: response.user.photoURL
          }
          this.loginServer (newUser);
        }
      )
      .catch(
        error => {
          console.log(error)
        }
      )
    },
    signUserInAuth ({ commit }, payload) {
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then(
            response => {
            const newUser = {
              uid: response.user.uid,
              name: response.user.displayName,
              email: response.user.email,
              photoUrl: response.user.photoURL
            }
            this.loginServer (newUser);
          }
        )
        .catch(
          error => {
            console.log(error)
          }
        )
    },
    signUserUpAuth (payload) {
      firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        .then(
          user => {
            // commit('setLoading', false)
            const newUser = {
              uid: user.uid,
              name: user.displayName,
              email: user.email,
              photoUrl: user.photoURL,
            }
            this.loginServer (newUser);
          },
        )
        .catch(
          error => {
            console.log(error)
          }
        )
    },*/
  }
}
</script>
